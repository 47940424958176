.hoox-header {
    padding: 10px 0;
    border-bottom: 1px solid var(--hoox-color-grey2);
    background: var(--hoox-color-white);
}

.hoox-header-section {
    position: sticky;
    top: 0;
    z-index: 10;
}

.hoox-header-lp-2 {
    padding: 14px 0;
}

.hoox-header-lp-3 {
    padding: 13.5px 0;
}

.hoox-header__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hoox-header__logo {
    max-height: 32px;
}

.hoox-header__logo img {
    max-height: 32px;
}

.hoox-header-lp-2 .hoox-header__logo img, .hoox-header-lp-3 .hoox-header__logo img {
    max-width: 225px;
    max-height: 36px;
}

.hoox-header__title {
    display: none;
}

.hoox-header__btn {
    display: none;
}

.hoox-header__button {
    font-size: 14px;
}


@media (min-width: 1024px) {
    .hoox-header__wrapper {
        justify-content: space-between;
    }

    .hoox-header-lp-2, .hoox-header-lp-3 {
        padding: 9px 0;
    }
    
    .hoox-header__logo {
        max-height: 40px;
    }

    .hoox-header-lp-2 .hoox-header__logo img,  .hoox-header-lp-3 .hoox-header__logo img {
        max-width: unset;
    }
    
    .hoox-header__logo img {
        max-height: 40px;
    }
    
    .hoox-header__title {
        color: var(--hoox-color-slate-secondary);
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
    }
    
    .hoox-header__btn {
        display: block;
        max-width: 265px;
        height: 42px;
    }

    .hoox-header__btn a {
        height: 42px;
    }
}

.hoox-header-lp-2 .hoox-header__btn, .hoox-header-lp-3 .hoox-header__btn {
    max-width: 166px;
}


/*Tablet Portrait Only*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
    .hoox-header__wrapper {
        justify-content: space-between;
    }
    
    .hoox-header__title {
        display: none;
    }

    .hoox-header__btn {
        display: none;
    }
}